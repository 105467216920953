
import React, { useState } from 'react'

export const SearchAndSidenavContext = React.createContext();

const defaultContextValue = {
  search: { active: false, set: () => {} },
  sidenav: { active: true, set: () => {} },
}

export function SearchAndSidenavProvider ({ children }) {
  const [sidenavActive, setSidenavActive] = useState(true);
  const [searchActive, setSearchActive] = useState(false);
  
  const contextValue = {
    ...defaultContextValue,
    search: { active: searchActive, set: (active) => setSearchActive(active) },
    sidenav: { active: sidenavActive, set: (active) => setSidenavActive(active) }
  }

  return (
    <SearchAndSidenavContext.Provider value={contextValue}>
      {children}
    </SearchAndSidenavContext.Provider>
  )
}

export function useSearchAndSideNav () {
  const context = React.useContext(SearchAndSidenavContext)

  return {
    ...defaultContextValue,
    ...context
  };
}


