
import axios from 'axios'
import { formatHash } from '../models/hash';

export class DownloadService {
  constructor () {
    this.generateDownloadLinkURL = "https://solidbook.io/api/download/generate";
  }

  async generateDownloadLink (hash, type) {
    hash = formatHash(hash);
    
    const response = await axios({
      method: 'POST',
      url: `${this.generateDownloadLinkURL}?hash=${hash}&type=${type}`
    });

    return response.data.download_link;
  }

}