class TextUtils {
  hashCode(s) {
    return s.split("").reduce((a, b) => ((a << 5) - a + b.charCodeAt(0)) | 0, 0)
  }

  createRandomString(numberDigits) {
    const chars = "0123456789"
    let value = ""

    for (let i = numberDigits; i > 0; --i) {
      value += chars[Math.round(Math.random() * (chars.length - 1))]
    }

    return value.toString()
  }

  toKebabCase(str) {
    str = this.lettersOnly(str);
    return str
      .replace(/([a-z])([A-Z])/g, "$1-$2")
      .replace(/\s+/g, "-")
      .toLowerCase()
  }

  replaceAll(word, match, replacement) {
    const hasIt = word.indexOf(match) !== -1
    word = word.replace(match, replacement)

    if (!hasIt) {
      return word
    } else {
      return this.replaceAll(word, match, replacement)
    }
  }

  validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  isImageLink(str) {
    return str.indexOf("/img") !== -1
  }

  lettersOnly(str) {
    return str.replace(/[^\w\s]/gi, '')
    //or return str.match(/[a-z]/gi).join('');
    //or return str.replace(/[^a-z]/gi,"");
  }

}

const textUtils = new TextUtils()

export { textUtils }
