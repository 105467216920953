
import React, { useState, useEffect } from "react";

import { cookieService, paymentService } from '.'

export const AuthContext = React.createContext();

const defaultContextValue = {
  isAuthenticated: false,
  isCheckingAuthState: false,
}

export function AuthProvider ({ children }) {

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isCheckingAuthState, setIsCheckingAuthState] = useState(true);

  useEffect(() => {
    checkAuthState();
  }, [])

  const checkAuthState = async () => {
    let paid = false;
    const hasHashInUrlIndex = window.location.href.indexOf('hash');
    const hasHashInUrl = hasHashInUrlIndex !== -1;
    let hash = null;

    hash = cookieService.getHashFromCookie();

    if (hasHashInUrl) {
      hash = window.location.href.substring(hasHashInUrlIndex + 5);
    }

    if (hash !== null && hash !== undefined) {
      paid = await paymentService.checkPaid(hash);
    }

    if (paid) {
      cookieService.saveHashAsCookie(hash);
      setIsAuthenticated(true)
    } else {
      cookieService.removeCookie();
    }

    setIsCheckingAuthState(false);
  }

  const contextValue = {
    ...defaultContextValue,
    isAuthenticated,
    isCheckingAuthState
  }

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  )
}

/**
 * I only want readers to have to check their auth state on initial page
 * load. I don't want them to have to do that again and again after that, you know?
 */

export function useAuth () {
  const context = React.useContext(AuthContext)
  // if (context === undefined) {
  //   throw new Error('useAuth must be used within a AuthContext')
  // }

  return {
    ...defaultContextValue,
    ...context
  };
}
