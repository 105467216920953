/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

const React = require("react")
const { AuthProvider } = require("./src/services/AuthProvider")
const {
  SearchAndSidenavProvider,
  useSearchAndSideNav,
} = require("./src/services/useSearchAndSideNav")
const {
  AppSettingsProvider,
  useAppSettings,
} = require("./src/services/AppSettings")
const { ChaptersProvider } = require("./src/services/useChapters")
const {
  default: Tab,
} = require("./src/components/shared/sidenav/components/Tab")
const { Helmet } = require("react-helmet")
const Sidenav = require("./src/components/shared/sidenav/components/Sidenav")
  .default

function RootSidenav() {
  const { darkMode } = useAppSettings()
  const { search, sidenav } = useSearchAndSideNav()

  const toggleSideNav = () => {
    sidenav.set(!sidenav.active)
  }

  return (
    <section>
      <Sidenav
        darkMode={darkMode}
        search={{
          active: search.active,
          onOpen: () => search.set(true),
          onClose: () => search.set(false),
        }}
        sidenavActive={sidenav.active}
        toggleSideNav={() => toggleSideNav()}
        currentPage={null}
      />
      <Tab
        darkMode={darkMode.active}
        sidenavActive={sidenav.active}
        onClick={() => toggleSideNav()}
      />
    </section>
  )
}


exports.wrapRootElement = ({ element }) => {
  // console.log('wrapping root')
  // setTimeout(() => {
  //   window.fbq("init", "909950680317843")
  // window.fbq("track", "PageView")
  // console.log(window.fbq)
  // }, 3000)



  return (
    <ChaptersProvider>
      <AppSettingsProvider>
        <SearchAndSidenavProvider>
          <AuthProvider>
            <RootSidenav />
            {element}
          </AuthProvider>
        </SearchAndSidenavProvider>
      </AppSettingsProvider>
    </ChaptersProvider>
  )
}

exports.onClientEntry = () => {
  eval(`
  !function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  `)
  

  try {
    window.fbq('init', '909950680317843');
    window.fbq('track', 'PageView');
  } catch (err) {

  }
}

exports.onRouteUpdate = () => {
  try { 
    window.fbq('track', 'PageView');
  } catch (err) {
    
  }
}
