
import React, { useState } from 'react';
import { textUtils } from '../utils/textUtils';
import { navigate } from 'gatsby'

const ChaptersContext = React.createContext();

const PUBLIC_CHAPTER_IDS = [
  '6a4d804e388747418a713a4dc09c64e3',
  '872fd41c47f54a61a318c6729ed7c0bd'
]

const defaultContextValue = {
  models: { openChapters: [], currentPage: undefined },
  operations: { 
    openChapter: () => {}, 
    closeChapter: () => {}, 
    closeAllChapters: () => {}, 
    setCurrentPage: () => {}, 
    isChapterOpen: () => {},
    toggleChapter: () => {},
    getChapterOrSectionIdFromPath: () => {},
    scrollSectionIntoView: () => {},
    isPublicChapter: () => {},
    nextSection: () => {},
    previousSection: () => {}
  }
}

export function ChaptersProvider ({ children }) {
  const [openChapters, setOpenChapters] = useState([]);
  const [currentPage, setCurrentPage] = useState(undefined);

  function isChapter (element) {
    return element.classList.contains("chapter")
  }

  function getChapterIdFromSection (element) {
    return element.dataset.chapterId;
  } 

  const openChapter = (chapterId) => {
    if (!isChapterOpen(chapterId)) {
      setOpenChapters(openChapters.concat(chapterId));
    }
  }

  const closeAllAndOpenChapterOrSection = (id) => { 

    let chapterId = "";
    let idToScrollIntoView = "";
    let distance = "";
    let behavior = "";
    const element = document.getElementById(id);

    // Section
    if (!isChapter(element)) {
      chapterId = getChapterIdFromSection(element);
      idToScrollIntoView = id;
      distance = "center";
      behavior = "smooth"
    } 
    
    // Chapter
    else {
      chapterId = id;
      idToScrollIntoView = id;
      distance = "start"
      behavior = "smooth";
    }

    setOpenChapters([chapterId])
    scrollSectionIntoView(idToScrollIntoView, distance, behavior);
    setCurrentPage(id);
  }

  const toggleChapter = (chapterId) => {
    if (isChapterOpen(chapterId)) {
      closeChapter(chapterId);
    } else {
      openChapter(chapterId);
    }
  }

  const isChapterOpen = (chapterId) => {
    return openChapters.find((c) => c === chapterId);
  }

  const closeChapter = (chapterId) => {
    setOpenChapters(openChapters.filter((c) => c !== chapterId));
  }

  const closeAllChapters = () => {
    setOpenChapters([])
  }

  const getChapterOrSectionIdFromPath = (pathname) => {
    const lastDashIndex = pathname.lastIndexOf("-");
    const hasId = lastDashIndex !== -1;

    if (!hasId) {
      throw new Error("No identifier at this route");
    } 

    let identifier = pathname.substring(lastDashIndex + 1);
    identifier = textUtils.replaceAll(identifier, "/", "");

    return identifier;
  }

  const scrollSectionIntoView = (id, startOrCenter, behavior) => {
    setTimeout(() => {
      const el = document.getElementById(id);
      el.scrollIntoView({ 
        // Must be either "smooth" or "auto"
        behavior, 
        // Must pass in either "start" or "center"
        block: startOrCenter 
      })
    }, 200)
  }

  const isPublicChapter = (id) => {
    const element = document.getElementById(id);

    if (isChapter(element)) {
      return PUBLIC_CHAPTER_IDS.find((i) => i === id);
    } else {
      const chapterId = getChapterIdFromSection(element);
      return PUBLIC_CHAPTER_IDS.find((i) => i === chapterId);
    }
  }

  const nextSection = () => {
    let currentId;

    try {
      currentId = getChapterOrSectionIdFromPath(window.location.pathname);
    } catch (err) {
      return;
    }

    const element = document.getElementById(currentId);

    // Section within chapter
    if (!isChapter(element)) {
      const sibling = element.nextElementSibling;
      const hasNext = !!sibling === true;

      // At the end of the chapter
      if (!hasNext) {
        const currentChapterId = element.dataset.chapterId;
        const chapterElement = document.getElementById(currentChapterId);
        const siblingChapter = chapterElement.nextElementSibling;
        const hasNextChapter = !!siblingChapter === true;

        if (hasNextChapter) {
          const next = siblingChapter.firstElementChild.href.split(window.location.origin)[1]
          navigate(next);
        }
      
      // In the middle of a chapter
      } else {
        const next = sibling.href.split(window.location.origin)[1]
        navigate(next);
      }
    } 
    
    // At a chapter
    else {
      const firstSectionElement = element.childNodes[1].firstElementChild;
      const next = firstSectionElement.href.split(window.location.origin)[1]
      navigate(next);
    }
  }

  const previousSection = () => {
    let currentId;
    
    try {
      currentId = getChapterOrSectionIdFromPath(window.location.pathname);
    } catch (err) {
      return;
    }
    
    const element = document.getElementById(currentId);

    // Regular section
    if (!isChapter(element)) {
      const sibling = element.previousElementSibling;
      const hasPrev = !!sibling === true;

      // At the top of a chapter
      if (!hasPrev) {
        const prev = element.parentElement.parentElement.children[0].href;
        const prevRoute = prev.split(window.location.origin)[1];
    
        navigate(prevRoute);
      } 
      
      // In the middle
      else {
        const prev = sibling.href.split(window.location.origin)[1]
        navigate(prev);
      }

    } 
    
    // At a chapter
    else {
      const previousChapter = element.previousElementSibling;
      previousChapter.classList.contains('chapter');

      if (!isChapter(previousChapter)) {
        return;
      }

      const previousChapterElements = previousChapter.children[1].children;
      const lastElement = previousChapterElements[previousChapterElements.length - 1];
      const prev = lastElement.href.split(window.location.origin)[1]
      navigate(prev);
    }
  }

  const contextValue = {
    ...defaultContextValue,
    models: { openChapters, currentPage },
    operations: { 
      getChapterOrSectionIdFromPath,
      openChapter, 
      closeChapter, 
      closeAllChapters, 
      setCurrentPage, 
      isChapterOpen,
      toggleChapter,
      closeAllAndOpenChapterOrSection,
      scrollSectionIntoView,
      isPublicChapter,
      nextSection,
      previousSection
    }
  }

  return (
    <ChaptersContext.Provider value={contextValue}>
      {children}
    </ChaptersContext.Provider>
  )
}

export function useChapters () {
  const context = React.useContext(ChaptersContext)

  return {
    ...defaultContextValue,
    ...context
  };
}