
import { PaymentService } from "./PaymentService";
import { CookieService } from "./CookieService";
import { FeedbackService } from "./FeedbackService";
import { DownloadService } from "./DownloadService";
import { AccountService } from "./AccountService";

const paymentService = new PaymentService();
const cookieService = new CookieService();
const feedbackService = new FeedbackService(cookieService);
const downloadService = new DownloadService();
const accountService = new AccountService();

export {
  paymentService,
  cookieService,
  feedbackService,
  downloadService,
  accountService
}