
import Cookie from 'js-cookie'

export class CookieService {
  constructor () {}

  setCookie (name, value) {
    Cookie.set(name, value);
  }

  getCookie (name) {
    return JSON.parse(Cookie.get(name));
  }

  saveHashAsCookie (hashValue) {
    Cookie.set('hash', hashValue);
  }

  getHashFromCookie () {
    return Cookie.get('hash');
  }

  removeCookie () {
    return Cookie.remove('hash');
  }
}