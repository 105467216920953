
import React, { useState } from 'react'
import { Link } from 'gatsby';
import toggleBlack from '../../../../images/toggle.svg'
import toggleWhite from '../../../../images/toggle-white.svg'
import "../styles/ChapterContents.sass"
import { useChapters } from '../../../../services/useChapters';
import { textUtils } from '../../../../utils/textUtils';

const ChapterTitle = ({ emoji, title }) => (
  <>
    {textUtils.isImageLink(emoji) ? (
        <div className="image-chapter-title"><img src={emoji}/> &nbsp;{title}</div>
      ) : (
        <div>{emoji} &nbsp;{title}</div>
      )}
  </>
)
 
const ChapterContents = (props) => {
  const { models, operations } = useChapters();

  const chapterOpen = operations.isChapterOpen(props.id);
  const hasContent = props.content.length !== 0;

  const toggleChapterOpen = (e) => {
    e.preventDefault();
    e.stopPropagation();
    operations.toggleChapter(props.id)
  }

  return (
    <div id={`${props.id}`} className={`chapter`}>
      <Link to={props.url}className="chapter-title">
        <ChapterTitle emoji={props.emoji} title={props.title}/>
        {hasContent ? (
          <>
            <img 
              onClick={(e) => toggleChapterOpen(e)} 
              className={`chapter-toggle toggle-black ${chapterOpen ? 'open' : 'closed'}`}
              src={toggleBlack}
            />
            <img 
              onClick={(e) => toggleChapterOpen(e)} 
              className={`chapter-toggle toggle-white ${chapterOpen ? 'open' : 'closed'}`}
              src={toggleWhite}
            />
          </>
        ) : ''}
        
      </Link>
      {hasContent ? (
        <section className={`chapter-content-container ${chapterOpen ? 'open' : 'closed'}`}>
        {props.content.map((content, i) => (
          <Link 
            key={i}
            to={content.url}
            id={content.id}
            data-chapter-id={content.chapterId}
            className={`chapter-content depth-${content.depth} ${models.currentPage === content.id ? "active" : ''}`}>
            {content.title}
          </Link>
        ))}
      </section>
      ) : ''}
    </div>
  )
}

export default ChapterContents;