
import React from 'react'
import "../styles/Tab.sass"

const Tab = ({ onClick, sidenavActive, darkMode }) => (
  <div 
    onClick={onClick} 
    className={`action-toggle-sidebar ${darkMode ? 'dark-mode' : ''} ${sidenavActive ? 'open' : 'closed'}`}>
    <span className="expand">▶</span>
    <span className="fold">◀</span>
  </div>
)

export default Tab;