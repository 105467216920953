
import React from 'react'
import "../styles/Search.sass"

import searchIcon from '../../../../assets/icons/search.svg'
import searchIconWhite from '../../../../assets/icons/search-white.svg'

const SearchButton = ({ onOpenSearch }) => {
  function isMacintosh() {
    if (typeof navigator !== undefined) {
      return navigator.platform.indexOf('Mac') > -1
    }
  }

  return (
    <div className="search-container">
      <div className="search-button" onClick={() => onOpenSearch()}>
        <section>
          <img className="icon-black" src={searchIcon}/>
          <img className="icon-white" src={searchIconWhite}/>
          <div>Search</div>
        </section>
        <div className="hot-keys">
          {isMacintosh() ? (
            <div className="command">⌘</div>
          ) : (
            <div className="command windows-command">SHIFT</div>
          )}
          <div className="command">K</div>
        </div>
      </div>
    </div>
  )
}

export default SearchButton;