
import axios from 'axios'
import { formatHash } from '../models/hash';

export class AccountService {
  constructor () {
    this.getAccountDetailsURL = "https://solidbook.io/api/account";
    this.assignBookToTeamMemberURL = "https://solidbook.io/api/account/purchases/assign"
  }

  async getAccountDetails (hash) {
    hash = formatHash(hash);
    
    const response = await axios({
      method: 'GET',
      url: `${this.getAccountDetailsURL}?hash=${hash}`
    });

    return response.data;
  }

  async assignBookToTeamMember (hash, email) {
    hash = formatHash(hash);
    
    const response = await axios({
      method: 'POST',
      url: `${this.assignBookToTeamMemberURL}?hash=${hash}&email=${email}`
    });

    return response.data;
  }
}