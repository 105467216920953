
import React, { useState } from 'react';
import { cookieService } from '.';

const AppSettingsContext = React.createContext();

const getDarkModeFromLocalStorage = () => {
  try {
    const mode = cookieService.getCookie('darkModeEnabled');
    console.log("Setting dark mode from local storage", mode);
    return mode;
  } catch (err) {
    console.log(err);
    return false;
  }
}

const DEFAULT_DARK_MODE_SETTING = getDarkModeFromLocalStorage();

const defaultContextValue = {
  darkMode: {
    active: DEFAULT_DARK_MODE_SETTING,
    set: () => { }
  }
}

export function AppSettingsProvider ({ children }) {

  const [darkModeEnabled, setDarkModeEnabled] = useState(DEFAULT_DARK_MODE_SETTING);

  const contextValue = {
    ...defaultContextValue,
    darkMode: {
      active: darkModeEnabled,
      set: (active) => {
        setDarkModeEnabled(active);
        cookieService.setCookie('darkModeEnabled', active);
      }
    }
  }

  return (
    <AppSettingsContext.Provider value={contextValue}>
      {children}
    </AppSettingsContext.Provider>
  )
}

export function useAppSettings () {
  const context = React.useContext(AppSettingsContext)

  return {
    ...defaultContextValue,
    ...context
  };
}