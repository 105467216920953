
import React from 'react'
import "../styles/Sidenav.sass"
import { useStaticQuery, graphql, Link } from 'gatsby';
import Search from '../../search/components/Search';
import { useHotkeys } from 'react-hotkeys-hook'
import Tab from './Tab';
import ChapterContents from './ChapterContents';
import DarkModeToggle from "react-dark-mode-toggle";
import { useAppSettings } from '../../../../services/AppSettings';
import { useChapters } from '../../../../services/useChapters';


const Sidenav = ({ tableOfContents, sidenavActive, toggleSideNav, search }) => {
  const { operations } = useChapters();
  const { darkMode } = useAppSettings();

  const activateSearch = () => search.onOpen();
  const deactiveSearch = () => search.onClose();

  useHotkeys('ctrl+k', () => activateSearch());
  useHotkeys('cmd+k', () => activateSearch());
  useHotkeys('shift+k', () => activateSearch());

  useHotkeys('shift+right', () => operations.nextSection());
  useHotkeys('shift+left', () => operations.previousSection());


  return (
    <div className={`sidenav-container ${sidenavActive ? "active" : 'closed'} ${darkMode.active ? 'dark-mode' : ''}`}>
      <nav className={`sidenav`}>
        <div className="title-and-search">
          <div className="dark-mode-toggle">
            <DarkModeToggle
              onChange={() => darkMode.set(!darkMode.active)}
              checked={darkMode.active}
              size={35}
            />
          </div>
          <Link to="/" className="title">solidbook.io</Link>
          <div className="subtitle">Introduction to software design & architecture</div>
          <Search 
            searchActive={search.active} 
            onOpenSearch={() => activateSearch()} 
            onClose={() => deactiveSearch()}
          />
        </div>
        {tableOfContents.map((chapter, i) => (
          <ChapterContents 
            key={i} 
            toggleChapter={() => {}}
            currentChapter={null}
            currentSectionId={null}
            {...chapter}
          />
        ))}
        <div className="from">Made by <a href="https://twitter.com/stemmlerjs">Khalil Stemmler</a> 🤠 • {new Date().getFullYear()}</div>
      </nav>
    </div>
  )
}

export default (props) => {
  const { currentPage } = props;
  const data = useStaticQuery(AllHeadingsQuery)
  let toc = data.allTocJson.edges.map((edge) => edge.node);

  const account = {
    emoji: '',
    id: 'account',
    sequenceNumber: -2,
    title: "Account",
    url: "/account",
    content: []
  }
  
  const downloads = {
    emoji: '',
    id: 'downloads',
    sequenceNumber: -1,
    title: "Downloads",
    url: "/downloads",
    content: []
  };

  toc.unshift(downloads);
  toc.unshift(account);
  

  return (
    <Sidenav 
      {...props}
      tableOfContents={toc}
    />
  )
};

const AllHeadingsQuery = graphql`
  query GetAllMarkdownAndFiles {
    allMarkdownRemark {
      edges {
        node {
          headings {
            value
            depth
          }
          fields {
            slug
            pageIdentifier
          }
        }
      }
    }

    allTocJson {
    edges {
      node {
        title
        url
        id 
        emoji
        sequenceNumber
        content {
          depth
          id
          chapterId
          title
          url
        }
      }
    }
  }
    
    allFile(filter: {ext: {eq: ".md"}}) {
      edges {
        node {
          absolutePath
          relativeDirectory
          relativePath
        }
      }
    }

    allDirectory(filter: {relativePath: {ne: ""}}) {
      edges {
        node {
          name
          relativePath
        }
      }
    }
  }
`